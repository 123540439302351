import { ReportService, ReportType, TStatus } from '@geovelo-frontends/commons';
import {
  Archive,
  CheckCircle as CheckIcon,
  ChevronLeft,
  ChevronRight,
  Error as ErrorIcon,
  PictureAsPdf,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';

function Header({
  openDownloadDialog,
}: {
  openDownloadDialog: (open: boolean) => void;
}): JSX.Element {
  const [title, setTitle] = useState<string>();
  const [parentRoute, setParentRoute] = useState<string | null | undefined>();
  const {
    user: { current: currentUser },
    zone: { map: zoneMap, parentMap, current: currentZone },
    report: { reports, allTypes: allReportTypes, selectedReport },
    stats: { exportDataTypes },
    actions: { setReports, setSelectedReport, setContributionId },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [typesMenuAnchorEl, setTypesMenuAnchorEl] = useState<HTMLLIElement | null>(null);

  useEffect(() => {
    if (currentZone) {
      const { administrativeLevel, name } = currentZone;

      setTitle(
        administrativeLevel === 'world'
          ? t('bicycle_facilities.administrative_levels.world') || ''
          : name,
      );
    } else {
      setTitle(undefined);
    }
  }, [currentZone]);

  useEffect(() => {
    if (currentZone && selectedReport !== undefined) {
      if (selectedReport) {
        const { administrativeLevel, countryCode, code } = currentZone;
        let _parentRoute = '';

        if (administrativeLevel === 'world') _parentRoute = '';
        else if (administrativeLevel === 'country') _parentRoute = `/${countryCode}`;
        else _parentRoute = `/${countryCode}/${code}`;

        _parentRoute += `/contributions`;

        setParentRoute(_parentRoute);
      } else {
        const parentId = parentMap[currentZone.id];
        if (parentId === null) {
          setParentRoute(null);
        } else if (parentId !== undefined) {
          const parent = zoneMap[parentId];
          if (parent) {
            const { administrativeLevel, countryCode, code } = parent;

            if (administrativeLevel === 'world') setParentRoute(`/`);
            else if (administrativeLevel === 'country') setParentRoute(`/${countryCode}`);
            else setParentRoute(`/${countryCode}/${code}`);
          } else {
            setParentRoute(null);
          }
        }
      }
    } else {
      setParentRoute(undefined);
    }
  }, [currentZone, parentMap, selectedReport]);

  useEffect(() => {
    if (selectedReport) {
      setTitle(
        `${t(
          allReportTypes?.find((value) => value.code === selectedReport.typeCode)?.titleKey ||
            'commons.report.default_title',
        )} #${selectedReport.id}`,
      );
    } else if (currentZone && selectedReport === null) {
      const { administrativeLevel, name } = currentZone;

      setTitle(
        administrativeLevel === 'world'
          ? t('bicycle_facilities.administrative_levels.world') || ''
          : name,
      );
    } else {
      setTitle(undefined);
    }
  }, [currentZone, selectedReport]);

  function seeOnOSM(): void {
    if (selectedReport?.osmNoteId) {
      window.open(`${environment.osm.apiUrl}/note/${selectedReport.osmNoteId}`, '_blank');
      setActionsMenuAnchorEl(null);
    }
  }

  function seeOnJOSM(): void {
    window.open('http://127.0.0.1:8111/load_and_zoom?' + selectedReport?.JOSMParams, '_blank');
    setActionsMenuAnchorEl(null);
  }

  function seeOnGeovelo(): void {
    window.open(
      selectedReport ? `${environment.frontendUrl}/reports/${selectedReport.id}` : '',
      '_blank',
    );
    setActionsMenuAnchorEl(null);
  }

  function seeItinerary(): void {
    if (selectedReport?.computedRouteId) {
      window.open(`${environment.frontendUrl}/route/${selectedReport.computedRouteId}`, '_blank');
      setActionsMenuAnchorEl(null);
    }
  }

  async function deleteReport(): Promise<void> {
    if (selectedReport) {
      try {
        await ReportService.deleteReport(selectedReport.id);
        reports?.splice(
          reports?.findIndex(({ id }) => id === selectedReport.id),
          1,
        );
        setReports([...(reports || [])]);
        setContributionId(null);
        if (parentRoute) navigate(parentRoute);
      } catch {
        enqueueSnackbar(t('commons.report.not_deleted'));
      }
      setActionsMenuAnchorEl(null);
    }
  }

  async function updateReport(data: { status?: TStatus; type?: ReportType }): Promise<void> {
    if (selectedReport) {
      try {
        const updatedReport = await ReportService.updateReport(selectedReport.id, data);
        reports?.splice(
          reports?.findIndex(({ id }) => id === selectedReport.id),
          1,
          updatedReport,
        );
        setReports([...(reports || [])]);
        setSelectedReport(updatedReport);
      } catch {
        enqueueSnackbar(t('commons.report.not_updated'));
      }
      setActionsMenuAnchorEl(null);
      setTypesMenuAnchorEl(null);
    }
  }

  const shpDownloadEnabled = environment.config.enableSHPDownload && exportDataTypes;
  const pdfDownloadEnabled =
    environment.config.pdfDownload &&
    currentZone &&
    currentZone.administrativeLevel === environment.config.pdfDownload.administrativeLevel;

  return (
    <Toolbar sx={{ gap: 2 }}>
      {parentRoute === undefined ? (
        <Skeleton height={30} sx={{ flexShrink: 0 }} variant="circular" width={30} />
      ) : (
        parentRoute && (
          <IconButton
            component={Link}
            onClick={() => setContributionId(null)}
            size="small"
            sx={{ flexShrink: 0 }}
            to={parentRoute}
          >
            <ChevronLeft />
          </IconButton>
        )
      )}
      <Box flexGrow={1} sx={{ overflowX: 'hidden' }}>
        <Typography noWrap component="h2" title={title} variant="h6">
          {title || <Skeleton variant="text" width={200} />}
        </Typography>
      </Box>
      {selectedReport ? (
        <Box alignItems="center" display="flex" flexShrink={0} gap={1}>
          {selectedReport.status === 'OPEN' ? (
            <ErrorIcon color="error" />
          ) : (
            <CheckIcon color="primary" />
          )}
          {currentUser && (
            <>
              <IconButton
                onClick={({ currentTarget }) => setActionsMenuAnchorEl(currentTarget)}
                size="medium"
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={actionsMenuAnchorEl}
                onClose={() => setActionsMenuAnchorEl(null)}
                open={Boolean(actionsMenuAnchorEl)}
              >
                <MenuItem
                  key="change_state"
                  onClick={() =>
                    updateReport({ status: selectedReport.status === 'OPEN' ? 'CLOSED' : 'OPEN' })
                  }
                >
                  <Typography>
                    {t(
                      selectedReport.status === 'OPEN'
                        ? 'commons.report.actions.to_solved'
                        : 'commons.report.actions.to_open',
                    )}
                  </Typography>
                </MenuItem>
                {selectedReport && (
                  <div>
                    <MenuItem
                      key="change_type"
                      onClick={({ currentTarget }) => setTypesMenuAnchorEl(currentTarget)}
                    >
                      <Typography>{t('commons.report.actions.change_type')}</Typography>
                      <Box flexGrow={1} />
                      <ChevronRight />
                    </MenuItem>

                    <Menu
                      keepMounted
                      anchorEl={typesMenuAnchorEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={() => setTypesMenuAnchorEl(null)}
                      open={Boolean(typesMenuAnchorEl)}
                    >
                      {allReportTypes?.map((type) => (
                        <MenuItem key={type.id} onClick={() => updateReport({ type })}>
                          <Typography>{t(type.titleKey)}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>

                    {selectedReport.osmNoteId && (
                      <MenuItem key="see_on_osm" onClick={() => seeOnOSM()}>
                        <Typography>{t('commons.report.actions.see_on_osm')}</Typography>
                      </MenuItem>
                    )}
                    <MenuItem key="see_on_josm" onClick={() => seeOnJOSM()}>
                      <Typography>{t('commons.report.actions.see_on_josm')}</Typography>
                    </MenuItem>
                    <MenuItem key="see_on_geovelo" onClick={() => seeOnGeovelo()}>
                      <Typography>{t('commons.report.actions.see_on_geovelo')}</Typography>
                    </MenuItem>
                    {selectedReport.computedRouteId && (
                      <MenuItem key="see_itinerary" onClick={() => seeItinerary()}>
                        <Typography>{t('commons.report.actions.see_itinerary')}</Typography>
                      </MenuItem>
                    )}
                    <MenuItem key="delete" onClick={() => deleteReport()}>
                      <Typography>{t('commons.actions.remove')}</Typography>
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </>
          )}
        </Box>
      ) : (
        (shpDownloadEnabled || pdfDownloadEnabled) && (
          <Box alignItems="center" display="flex" flexShrink={0} gap={1}>
            {shpDownloadEnabled && (
              <Button
                color="primary"
                disabled={!currentZone}
                onClick={() => openDownloadDialog(true)}
                size="small"
                startIcon={<Archive />}
                variant="outlined"
              >
                SHP
              </Button>
            )}
            {pdfDownloadEnabled && (
              <Button
                color="primary"
                href={`${environment.config.pdfDownload?.bucketUrl}/${currentZone.ref}.pdf`}
                rel="noreferrer"
                size="small"
                startIcon={<PictureAsPdf />}
                target="_blank"
                variant="outlined"
              >
                PDF
              </Button>
            )}
          </Box>
        )
      )}
    </Toolbar>
  );
}

export default Header;
